import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
const ContainerHeaderHeight = "3rem";
const DefaultPadding = "0.5rem";
const rootPage = makeStyles({
    page: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        flexGrow: 1,
    },
    root: {
        width: "100%",
        overflowY: "auto",
    },
    header: {
        height: ContainerHeaderHeight,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "sticky",
        top: 0,
        backgroundColor: tokens.colorNeutralBackground2,
        zIndex: 1000,
        paddingLeft: "1rem",
    },
    divider: {
        flexGrow: 0,
        ...shorthands.padding(0, DefaultPadding),
        width: "99%",
        left: "0.5%",
        position: "sticky",
        top: ContainerHeaderHeight,
        zIndex: 1000,
    },
    body: {
        ...shorthands.padding("2.9em", "1em", "1em", "1em"),
    },
    wrapper: {
        height: "86%",
    },
    spinner: {
        ...shorthands.margin("15%"),
    },
    tabList: {
        paddingTop: "1rem",
    },
    pageTitle: {
        paddingLeft: "0.625rem",
    },
});
const emptyBody = makeStyles({
    emptyContainer: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        alignItems: "center",
    },
    image: {
        width: "34vw",
    },
    text: {
        fontSize: "1rem",
        fontWeight: 600,
        lineHeight: "1.375rem",
        color: tokens.colorNeutralForeground4,
    },
});
const settingsPage = makeStyles({
    header: {
        position: "absolute",
        top: ContainerHeaderHeight,
        right: 0,
    },
});
const cardsBody = makeStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        ...shorthands.padding(0),
        ...shorthands.margin(0),
    },
    cardContainer: {
        ...shorthands.padding("0.625rem"),
        width: "18.625rem",
        height: "12.3125rem",
        marginTop: "0.625rem",
    },
    cardBody: {
        width: "16.75rem",
        display: "flex",
        height: "100%",
        alignItems: "flex-start",
        ...shorthands.overflow("hidden"),
    },
    cardBodyItem: {
        display: "flex",
        flexDirection: "column",
    },
    order1: {
        order: 1,
    },
    order2: {
        order: 2,
    },
    line: {
        display: "flex",
        ...shorthands.padding(DefaultPadding, 0),
        fontSize: "0.875rem",
        lineHeight: "1.125rem",
        paddingLeft: "0.375rem",
        columnGap: "0.375rem",
    },
    link: {
        cursor: "pointer",
        display: "flex",
    },
    text: {
        ...shorthands.overflow("hidden"),
        width: "14.25rem",
        display: "block",
    },
    linkText: {
        position: "relative",
        ...shorthands.textDecoration("underline"),
        ...shorthands.transition("color", "500ms"),
        ":hover,:focus": {
            color: tokens.colorStrokeFocus2,
            ...shorthands.textDecoration("none"),
        },
        "::after": {
            content: "''",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "0.1em",
            backgroundColor: tokens.colorBrandBackground,
            opacity: 0,
            ...shorthands.transition("transform", "300ms"),
            transform: "scale(0)",
        },
        ":hover::after,:focus::after": {
            opacity: "1",
            transform: "scale(1)",
        },
    },
    badge: {
        position: "absolute",
        bottom: "1rem",
        right: "1rem",
        textTransform: "lowercase",
    },
    bodyPadding: {
        paddingLeft: "0.25rem",
    },
    noDescription: {
        color: tokens.colorNeutralStrokeDisabled,
    },
});
const details = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "30rem",
    },
    section: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "2rem",
    },
    sectionTitle: {
        paddingBottom: "0.75rem",
        color: tokens.colorStrokeFocus2,
    },
    row: {
        display: "flex",
        fontSize: "0.875rem",
        paddingLeft: "0.375rem",
        color: tokens.colorNeutralForeground2,
        lineHeight: "2.5rem",
    },
    rowItemPropertyName: {
        width: "8rem",
    },
    persona: {
        top: "0.8em",
        position: "relative",
    },
});
const deleteModal = makeStyles({
    root: {
        width: "28.813rem",
    },
    message: {
        lineHeight: "1.625rem",
    },
    link: {
        display: "flex",
    },
    title: {
        paddingBottom: "0.75rem",
    },
    error: {
        overflowY: "auto",
        height: "9.375rem",
        width: "28.813rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
});
export { cardsBody, ContainerHeaderHeight, DefaultPadding, deleteModal, details, emptyBody, rootPage, settingsPage };
