import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from "@apollo/client";
import { Title1 } from "@fluentui/react-components";
import { Delete20Regular, Eye20Regular, Pen20Regular } from "@fluentui/react-icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUserRoles } from "../../../hooks";
import { Card } from "../../../library/src";
import { setSelectedSite } from "../../../redux/appStateSlice";
import { updateSites } from "../../../redux/tenantsSlice";
import DeleteDialog from "../../shared/sharedDeleteUtils";
import { deleteModal as useDeleteStyles, rootPage as useRootStyles, cardsBody as useStyles, } from "../../shared/sharedStyles";
import SiteCardBody from "./SiteCardBody";
const DELETE_SITE = gql `
  mutation DeleteSite($input: DeleteSiteInput!) {
    deleteSite(input: $input) {
      siteId
    }
  }
`;
const SitesCards = ({ sites, tenantIdOrKey, openDetailsView, openEditPanel }) => {
    const styles = useStyles();
    const deleteStyles = useDeleteStyles();
    const rootStyles = useRootStyles();
    const { tenantAdmin } = useUserRoles();
    const dispatch = useDispatch();
    const onClickOpenDetailsView = (site) => {
        openDetailsView(site);
    };
    const onClickOpenEditPanel = (site) => {
        openEditPanel(site);
    };
    const [siteToBeDeleted, setSiteToBeDeleted] = useState();
    const [currentSites, setCurrentSites] = useState(sites);
    const selectedSite = useSelector((state) => state.appState.selectedSite);
    const reduxSites = useSelector((state) => state.tenant.sites);
    const menuCard = (site) => {
        return [
            {
                key: "site-menu-1",
                name: "Details",
                icon: _jsx(Eye20Regular, {}),
                onClick: () => {
                    onClickOpenDetailsView(site);
                },
            },
            {
                key: "site-menu-2",
                name: "Edit",
                icon: _jsx(Pen20Regular, {}),
                onClick: () => {
                    onClickOpenEditPanel(site);
                },
            },
            {
                key: "site-menu-3",
                name: "Delete",
                icon: _jsx(Delete20Regular, {}),
                disabled: !tenantAdmin || sites.length <= 1,
                onClick: () => {
                    setSiteToBeDeleted(site);
                },
            },
        ];
    };
    const deleteDialog = () => {
        return siteToBeDeleted ? (_jsx(DeleteDialog, { itemName: siteToBeDeleted.name, title: `Are you sure you want to delete the ${siteToBeDeleted.name} site?`, deletingMessage: "Deleting site ...", successMessage: `Site ${siteToBeDeleted.name} successfully deleted.`, errorMessageTitle: "An error occurred while attempting to delete the site", itemId: siteToBeDeleted.id, mutation: DELETE_SITE, deleteInput: {
                siteIdOrName: siteToBeDeleted.id,
                tenantIdOrKey: tenantIdOrKey,
            }, queryToRefetch: "getSites", resetItemToBeDeleted: setSiteToBeDeleted, deleteReturnPropertyName: "deleteSite", onSuccess: () => {
                const updatedSites = currentSites.filter((s) => s.id !== siteToBeDeleted.id);
                setCurrentSites(updatedSites);
                dispatch(updateSites(updatedSites));
                siteToBeDeleted.id === selectedSite.id && dispatch(setSelectedSite(currentSites[0]));
            }, children: _jsx(_Fragment, { children: _jsx("div", { className: deleteStyles.message, children: "Deleting this site will also remove all system links and jobs associated with it. This action cannot be undone." }) }) })) : (_jsx(_Fragment, {}));
    };
    return (_jsxs(_Fragment, { children: [deleteDialog(), _jsxs("div", { className: rootStyles.wrapper, children: [_jsx(Title1, { className: rootStyles.pageTitle, children: "Sites" }), _jsx("div", { className: styles.container, children: sites
                            .slice()
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((site) => {
                            var _a;
                            return (_jsx("div", { className: styles.cardContainer, children: _jsx(Card, { headerText: site.name, headerBadge: true, onClickHeader: () => {
                                        onClickOpenDetailsView(site);
                                    }, menuItems: menuCard(site), isNew: (_a = reduxSites === null || reduxSites === void 0 ? void 0 : reduxSites.find((s) => s.id === site.id)) === null || _a === void 0 ? void 0 : _a.isNew, children: _jsx(SiteCardBody, { site: site }) }) }, site.id));
                        }) })] })] }));
};
export default SitesCards;
